import { state } from ":mods";
import { getSubModuleDetails } from "../../apis";
import { Match, Switch, createEffect } from "solid-js";
import { createSubmoduleInputs } from "../../forms";

export function SubmoduleForm({
  submoduleID,
  buttonMsg,
  onDone,
}: {
  submoduleID?: number | string;
  buttonMsg?: string;
  onDone?: (values: ReturnType<ReturnType<typeof createSubmoduleInputs>["Actions"]["getValuesSnakecase"]>) => void;
}) {
  const Form = createSubmoduleInputs();
  const $module_details = state.createAsync([getSubModuleDetails, submoduleID], {
    manualFetch: { startSuccess: true },
  });
  if (submoduleID !== undefined) {
    $module_details.on((event, { value }) => {
      if (submoduleID) {
        console.log("updating with module details");
        Form.Actions.update($module_details.unwrap.value.data);
      }
    });
    $module_details.refetch();
  }
  // createEffect(() => {
  //   console.log("from effect of module-form :: ", $module_details?.value?.data);
  // });

  function onSubmit() {
    const values = Form.Actions.getValuesSnakecase();
    console.log("on submit ::: ", values);
    if (onDone) {
      onDone(values);
    }
  }
  return (
    <Switch fallback={<div>unknown promise state</div>}>
      <Match when={$module_details.state.loading}>Loading Module Details...</Match>
      <Match when={$module_details.state.failed}>An error occured while loading module details {}...</Match>
      <Match when={$module_details.state.success}>
        <div class="w-full h-full flex flex-col">
          <Form.ID.Label title="ID" />
          <Form.ID.Input class="form-input" type="text" />
          <Form.Name.Label title="Name" />
          <Form.Name.Input class="form-input" type="text" />

          <Form.Actions.Button
            class="form-btn !w-fit !min-h-50px px-2 mt-2"
            statusValid={buttonMsg ?? "Create Sub Module"}
            onSubmit={onSubmit}
            onlyStatus={["valid"]}
          />
        </div>
      </Match>
    </Switch>
  );
}
